import React from 'react';
import './header.scss';
import { Logo } from '../logo/logo';

export function Header() {
  return (
    <>
      <header>
        <h1 id="brand">Jeremiah Shore</h1>
        <p id="tagline">singer / songwriter / guitarist</p>
        <Logo />
      </header>
      <nav>
        <ul id="links">
          <li class="link">
            <a href="https://soundcloud.com/user-620867192" target="_blank">soundcloud</a>
          </li>
          <li class="link">
            <a href="mailto:contact@jeremiahshore.net">email</a>
          </li>
          <Logo />
          <li class="link">
            <a href="https://www.instagram.com/jeremiahshoremusic" target="_blank">instagram</a>
          </li>
          <li class="link">
            <a href="https://www.facebook.com/jeremiahshoremusic" target="_blank">facebook</a>
          </li>
        </ul>
      </nav>
    </>
  );
}