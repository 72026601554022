import React from 'react';
import './soundcloud-embed.scss';

export class SoundCloudEmbed extends React.Component {
  render(props) {
    return(
      <div class="embed-card">
      <iframe width="100%" 
        scrolling="no" 
        frameborder="no" 
        allow="autoplay" 
        src={this.props.embedLink}
      />
      <div class="embed-card-caption">
        <a class="sc-link" 
          href={this.props.songPageLink} 
          title={this.props.songTitle} 
          target="_blank" 
        >
          {this.props.songTitle}
        </a>
      </div>
  </div>
    );
  }
}