import React from 'react';
import { Header } from '../header/header';

export function Page(props) {
  return (
    <>
      <Header />
      
      {props.children}

      <footer>
        &copy; 2020 Jeremiah Shore; all rights reserved.
      </footer>
    </>
  );
}