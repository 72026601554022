import React from 'react';
import { SoundCloudEmbed } from '../../soundcloud-embed/soundcloud-embed';
import { Page } from '../../page/page';

export class HomePage extends React.Component {
  render() {
    const styleString = "font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;";
    return (
      <Page>
        <section id="featured-tracks">
          <h2>Featured Tracks</h2>
          <div id="sc-embeds">
            <SoundCloudEmbed songTitle="Patterns (2019 demo)"
              embedLink="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/827733286&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              songPageLink="https://soundcloud.com/user-620867192/patterns-2019-demo"
            />
            <SoundCloudEmbed songTitle="Requiem (hindsight demo)"
              embedLink="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/823387960&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              songPageLink="https://soundcloud.com/user-620867192/requiem"
            />
            <SoundCloudEmbed songTitle="Cast Away (demo)"
              embedLink="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/544217046&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              songPageLink="https://soundcloud.com/user-620867192/cast-away"
            />
            <SoundCloudEmbed songTitle="Say Anything (demo)"
              embedLink="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/543281667&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              songPageLink="https://soundcloud.com/user-620867192/say-anything"
            />
          </div>
        </section>
      </Page>
    );
  }
}